import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoVelimaq from '../../assets/logo_velimaq.png';

const handleGeneratePDF = (reporte) => {
  if (!reporte || !reporte.responses) {
    console.error('Datos del reporte inválidos:', reporte);
    return;
  }

  const data = reporte;
  
  const getValue = (key) => {
    if (!data.responses) return '';
    const response = data.responses.find(r => r.key === key);
    return response ? response.value : '';
  };

  const doc = new jsPDF();
  
  doc.setProperties({
    title: 'Informe de Mantenimiento',
    author: 'VELIMAQ',
    subject: 'Maintenance Report',
  });

  // Logo y título centrado
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  doc.text('INFORME DE MANTENIMIENTO', doc.internal.pageSize.width/3, 15, {align: 'center'});
  
  

  // Use the imported logo
  doc.addImage(logoVelimaq, 'PNG', doc.internal.pageSize.width - 90, 5, 75, 15);

  // Tabla de metadata con validación
  const fecha = getValue('fecha') || '';
  const formattedFecha = fecha ? fecha.slice(2) : '';
  
  doc.autoTable({
    startY: 25,
    head: [],
    body: [
      [
        {content: `FECHA: ${formattedFecha}`, styles: {cellWidth: 60}},
        {content: 'FORMATO: 1.0', styles: {cellWidth: 60}},
        {content: `N°: ${data.id ? `0${data.id}` : ''}`, styles: {cellWidth: 60}}
      ]
    ],
    theme: 'grid',
    styles: {
      fontSize: 8,
      cellPadding: 2,
      halign: 'left'
    },
    margin: {left: 15, right: 15},
    tableWidth: 'auto'
  });

  // Sección datos de la unidad
  doc.setFillColor(240, 240, 240);
  doc.rect(15, 40, doc.internal.pageSize.width - 30, 8, 'F');
  doc.setFontSize(10);
  doc.text('DATOS DE LA UNIDAD', 17, 45);

  // Tabla de datos de la unidad con validación
  doc.autoTable({
    startY: 48,
    head: [],
    body: [
      [
        {content: `OT: ${data.id ? `0${data.id}` : ''}`, styles: {cellWidth: 60}},
        {content: `TIPO DE COMBUSTIBLE: ${getValue('tipo de combustible') || ''}`, styles: {cellWidth: 80}},
        {content: `OBRA: ${getValue('obra') || ''}`, styles: {cellWidth: 40}}
      ],
      [
        {content: `CÓDIGO: ${getValue('codigo') || ''}`, styles: {cellWidth: 60}},
        {content: `EQUIPO: ${getValue('equipo') || ''}`, styles: {cellWidth: 80}},
        {content: '', styles: {cellWidth: 40}}
      ],
      [
        {content: `MODELO: ${getValue('modelo') || ''}`, styles: {cellWidth: 60}},
        {content: `N° DE SERIE: ${getValue('n° de serie') || ''}`, styles: {cellWidth: 80}},
        {content: '', styles: {cellWidth: 40}}
      ]
    ],
    theme: 'grid',
    styles: {
      fontSize: 8,
      cellPadding: 2,
      halign: 'left'
    },
    margin: {left: 15, right: 15},
    tableWidth: 'auto'
  });

  // Sección falla y diagnóstico con nuevo estilo
  doc.setFillColor(240, 240, 240);
  doc.rect(15, 85, doc.internal.pageSize.width - 30, 8, 'F');
  doc.text('FALLA Y DIAGNÓSTICO', 17, 90);

  doc.autoTable({
    startY: 93,
    head: [],
    body: [
      [`KILOMETRAJE/HOROMETRO: ${getValue('kilometraje/horometro') || ''}`],
      [`REPORTE DE FALLA: ${getValue('reporte de falla') || ''}`],
      [`REPUESTOS: ${getValue('causalidad') || ''}`],
      [`DESCRIPCION DE ACTIVIDADES: ${getValue('descripcion de falla') || ''}`],
      [`PORCENTAJE DE AVANCE (Fundamentalo): ${getValue('accion correctiva') || ''}`],
      [`ESTATUS FINAL: ${getValue('estatus final') || '-'}`]
    ],
    theme: 'grid',
    styles: {
      fontSize: 8,
      cellPadding: 3,
      halign: 'left'
    },
    margin: {left: 15, right: 15},
    tableWidth: 'auto'
  });

  // Sección de evidencias
  doc.setFillColor(240, 240, 240);
  doc.rect(15, 160, doc.internal.pageSize.width - 30, 8, 'F');
  doc.text('EVIDENCIAS', 17, 165);

  // Mantener el código existente para imágenes
  if (data.image_paths && data.image_paths.length > 0) {
    const imagePromises = data.image_paths.map(imagePath => {
      const imageUrl = `https://velimaqappoffline.smartaccesorios.shop/.phpOffSSOMA${imagePath.substring(1)}`;
      return urlToBase64(imageUrl).catch(error => {
        console.error(`Error con imagen ${imageUrl}:`, error);
        return null;
      });
    });

    Promise.all(imagePromises)
      .then(base64Images => {
        try {
          const validImages = base64Images.filter(img => img !== null);
          
          // Agregar nueva página para comenzar con las imágenes
          doc.addPage();
          
          // Procesar imágenes en pares
          for (let i = 0; i < validImages.length; i += 2) {
            if (i > 0) doc.addPage();
            
            // Primera imagen del par
            doc.addImage(validImages[i], 'JPEG', 15, 15, 180, 120);
            
            // Segunda imagen del par (si existe)
            if (i + 1 < validImages.length) {
              doc.addImage(validImages[i + 1], 'JPEG', 15, 145, 180, 120);
            }
            
            // Si es la última página y hay un número impar de imágenes
            // Agregar firmas debajo de la última imagen
            if (i + 2 >= validImages.length && validImages.length % 2 !== 0) {
              const signatureY = 145;
              addSignatures(doc, signatureY);
            }
          }
          
          // Si hay un número par de imágenes, agregar página nueva para firmas
          if (validImages.length % 2 === 0) {
            doc.addPage();
            addSignatures(doc, 200);
          }
          
          doc.save(`informe-mantenimiento-${data.id}.pdf`);
        } catch (error) {
          console.error('Error al procesar las imágenes:', error);
          handleError(doc, getValue);
        }
      })
      .catch(error => {
        console.error('Error al cargar las imágenes:', error);
        handleError(doc, getValue, data);
      });
  } else {
    addSignatures(doc, 200);
    doc.save(`informe-mantenimiento-${data.id}.pdf`);
  }
};

// Función para convertir URL a base64
const urlToBase64 = async (url) => {
  try {
    // Crear un elemento imagen
    const img = new Image();
    
    return new Promise((resolve, reject) => {
      img.crossOrigin = 'anonymous'; // Importante para CORS
      
      img.onload = () => {
        // Crear un canvas para convertir la imagen
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        
        // Dibujar la imagen en el canvas
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        
        // Convertir a base64
        const dataURL = canvas.toDataURL('image/jpeg');
        resolve(dataURL);
      };
      
      img.onerror = () => {
        reject(new Error('Error al cargar la imagen'));
      };

      // Agregar timestamp para evitar caché
      img.src = `${url}?t=${new Date().getTime()}`;
    });
  } catch (error) {
    console.error('Error convirtiendo imagen a base64:', error);
    throw error;
  }
};

// Modificar función de firmas para aceptar posición Y
const addSignatures = (doc, signatureY) => {
  doc.setFontSize(10);
  
  // Rectangles
  doc.rect(20, signatureY, 50, 30);
  doc.rect(80, signatureY, 50, 30);
  doc.rect(140, signatureY, 50, 30);
  
  // Centered text using doc.text with alignment option
  doc.text('FIRMA DEL SUPERVISOR', 45, signatureY + 40, { align: 'center' });
  doc.text('FIRMA PLANNER DE\nMANTENIMIENTO', 105, signatureY + 40, { align: 'center' });
  doc.text('FIRMA DEL TÉCNICO', 165, signatureY + 40, { align: 'center' });
};

const handleError = (doc, getValue, data) => {
  addSignatures(doc, 200);
  doc.save(`informe-mantenimiento-0${data.id}.pdf`);
};

export default handleGeneratePDF;