import React, { useState, useEffect } from "react";
import { FiX, FiPlus, FiTrash, FiPrinter } from "react-icons/fi";
import { formatDate } from "../ayuda/formatDate";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DailyActivityPDF from "./DailyActivityPDF";
import ImageUploader from "../ayuda/ImageUploader";
import {
  saveDailyActivity,
  getDailyActivityById,
  modifyDailyActivity,
  deleteDailyActivity,
} from "../../idb"; // Asegúrate de que este archivo exista con las funciones IDB

const initialState = {
  machineryId: "",
  date: new Date().toISOString().split("T")[0],
  activities: [{ startTime: "", description: "", status: "" }],
  observations: "",
  morningShift: { start: "", end: "" },
  afternoonShift: { start: "", end: "" },
  warmupTime: "",
  totalKm: "",
  totalHours: "",
  oilLevel: "",
  initialFuel: "",
  finalFuel: "",
  initialHourmeter: "",
  finalHourmeter: "",
  coolantLevel: "",
  hydraulicOilLevel: "",
  brakeFluidLevel: "",
  transmissionOilLevel: "",
  operatorId: "",
  assistants: [],
  generalState: "",
  synced: false,
  approved: false,
  approvalStatus: "pending",
  operatorSignature: null,
  supervisorSignature: null,
  lastModified: new Date().toISOString(),
};

const DailyActivitiesModal = ({
  isOpen,
  onClose,
  activityId,
  usersios,
  maquineries,
}) => {
  const [form, setForm] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (isOpen && activityId) {
      loadActivity(activityId);
    } else {
      setForm(initialState);
      setImages([]);
    }
  }, [isOpen, activityId]);

  const loadActivity = async (id) => {
    setLoading(true);
    try {
      const activity = await getDailyActivityById(id);
      if (activity) {
        setForm(activity);
        if (activity.images) {
          setImages(activity.images);
        }
      }
    } catch (error) {
      console.error("Error al cargar la actividad:", error);
    }
    setLoading(false);
  };

  const handleChange = (e, nestedKey) => {
    const { name, value } = e.target;
    setForm((prev) =>
      nestedKey
        ? { ...prev, [nestedKey]: { ...prev[nestedKey], [name]: value } }
        : { ...prev, [name]: value }
    );
  };

  const handleActivityChange = (index, field, value) => {
    setForm((prev) => {
      const newActivities = [...prev.activities];
      newActivities[index] = { ...newActivities[index], [field]: value };
      return { ...prev, activities: newActivities };
    });
  };

  const addActivity = () =>
    setForm((prev) => ({
      ...prev,
      activities: [
        ...prev.activities,
        { startTime: "", description: "", status: "" },
      ],
    }));

  const removeActivity = (index) =>
    setForm((prev) => ({
      ...prev,
      activities: prev.activities.filter((_, i) => i !== index),
    }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const newActivity = {
        ...form,
        synced: false,
        approved: false,
        approvalStatus: "pending",
        supervisorSignature: null,
        lastModified: new Date().toISOString(),
        creacion: formatDate(new Date()),
        images: images
      };

      if (form.id) {
        await modifyDailyActivity(form.id, newActivity);
      } else {
        await saveDailyActivity(newActivity);
      }
      onClose();
    } catch (error) {
      console.error("Error al guardar la actividad:", error);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    if (
      form.id &&
      window.confirm("¿Estás seguro de que quieres eliminar esta actividad?")
    ) {
      setLoading(true);
      try {
        await deleteDailyActivity(form.id);
        onClose();
      } catch (error) {
        console.error("Error al eliminar la actividad:", error);
      }
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  const operators = usersios.filter((user) => ["Operador", "SuperAdmin", "Supervisor"].includes(user.rol));
  const assistants = usersios.filter((user) => user.rol === "Asistente");

  const getMachineryName = (id) => {
    const machinery = maquineries.find((m) => m.id === Number(id));
    return machinery ? machinery.nombre : "Desconocido";
  };

  console.log(maquineries);

  const getUserName = (id) => {
    const user = usersios.find((u) => u.id === Number(id));
    return user
      ? `${user.nombres} ${user.apPaterno} ${user.apMaterno}`
      : "Desconocido";
  };

  const getPDFData = (formData) => {
    return {
      ...formData,
      machineryName: getMachineryName(formData.machineryId),
      operator: getUserName(formData.operatorId),
      assistants: formData.assistants.map(getUserName).join(", "),
      images: images
    };
  };

  return (
    <>
      {form.synced === false ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-2">
          <div className="bg-white rounded-lg p-3 w-full max-w-md max-h-[90vh] overflow-y-auto text-xs">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-sm font-semibold">
                {form.id ? "Editar" : "Nueva"} Actividad
              </h2>
              {form.id && (
                <PDFDownloadLink
                  document={<DailyActivityPDF data={getPDFData(form)} />}
                  fileName={`actividad_diaria_${form.id}.pdf`}
                >
                  {({ loading }) => (
                    <button
                      className="text-blue-500 hover:text-blue-700 flex items-center text-[11px] md:text-xs"
                      disabled={loading}
                    >
                      <FiPrinter className="h-5 w-5" />
                      Imprimir
                    </button>
                  )}
                </PDFDownloadLink>
              )}
              <button
                className="p-1 text-white bg-red-500 hover:bg-red-600 rounded-full"
                onClick={onClose}
              >
                <FiX size={20} />
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-3">
              <select
                name="machineryId"
                value={form.machineryId}
                onChange={handleChange}
                className="w-full p-1.5 border rounded text-xs"
                required
              >
                <option value="">Seleccione Maquinaria</option>
                {maquineries
                .filter(machinery => machinery.estado !== "Inactivo")
                .map((machinery) => (
                  <option key={machinery.id} value={machinery.id}>
                    <span className="inline-block w-32">{machinery.codigo}||</span>{machinery.nombre}
                  </option>
                ))}
              </select>

              <input
                type="date"
                name="date"
                value={form.date}
                onChange={handleChange}
                className="w-full p-1.5 border rounded text-xs"
              />

              <div className="space-y-2">
                <h3 className="font-medium text-xs">Actividades</h3>
                {form.activities.map((act, i) => (
                  <div key={i} className="flex space-x-1">
                    <input
                      type="time"
                      value={act.startTime}
                      onChange={(e) =>
                        handleActivityChange(i, "startTime", e.target.value)
                      }
                      className="p-1.5 border rounded text-xs"
                    />
                    <input
                      value={act.description}
                      onChange={(e) =>
                        handleActivityChange(i, "description", e.target.value)
                      }
                      placeholder="Descripción"
                      className="flex-grow p-1.5 border rounded text-xs"
                    />
                    <select
                      value={act.status}
                      onChange={(e) =>
                        handleActivityChange(i, "status", e.target.value)
                      }
                      className="p-1.5 border rounded text-xs"
                    >
                      <option value="">Estado</option>
                      <option value="produccion">Producción</option>
                      <option value="disponible">Disponible</option>
                      <option value="mantenimiento">Mantenimiento</option>
                      <option value="varado">Varado</option>
                    </select>
                    <button
                      type="button"
                      onClick={() => removeActivity(i)}
                      className="bg-red-500 text-white p-1.5 rounded"
                    >
                      <FiTrash size={12} />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addActivity}
                  className="bg-green-500 text-white px-3 py-1.5 rounded flex items-center text-xs"
                >
                  <FiPlus className="mr-1" size={12} /> Agregar
                </button>
              </div>

              <textarea
                name="observations"
                value={form.observations}
                onChange={handleChange}
                placeholder="Observaciones"
                className="w-full p-1.5 border rounded text-xs"
              />

              <div className="grid grid-cols-2 gap-2">
                <div className="bg-green-50/50 p-4 rounded-xl">
                  <label className="text-xs">Inicio Mañana</label>
                  <input
                    type="time"
                    name="start"
                    value={form.morningShift.start}
                    onChange={(e) => handleChange(e, "morningShift")}
                    className="w-full p-1.5 border rounded text-xs"
                  />
                  <label className="text-xs">Fin Mañana</label>
                  <input
                    type="time"
                    name="end"
                    value={form.morningShift.end}
                    onChange={(e) => handleChange(e, "morningShift")}
                    className="w-full p-1.5 border rounded mt-1 text-xs"
                  />
                </div>
                <div className="bg-red-50/50 p-4 rounded-xl">
                  <label className="text-xs mt-4 pt-4">Inicio Tarde</label>
                  <input
                    type="time"
                    name="start"
                    value={form.afternoonShift.start}
                    onChange={(e) => handleChange(e, "afternoonShift")}
                    className="w-full p-1.5 border rounded text-xs"
                  />
                  <label className="text-xs mt-4 pt-4 ">Fin Tarde</label>
                  <input
                    type="time"
                    name="end"
                    value={form.afternoonShift.end}
                    onChange={(e) => handleChange(e, "afternoonShift")}
                    className="w-full p-1.5 border rounded mt-1 text-xs"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2">
                <input
                  name="warmupTime"
                  value={form.warmupTime}
                  onChange={handleChange}
                  placeholder="Calentamiento"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="totalKm"
                  value={form.totalKm}
                  onChange={handleChange}
                  placeholder="Total de Kilometros"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="totalHours"
                  value={form.totalHours}
                  onChange={handleChange}
                  placeholder="Total Horas Trabajadas"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="oilLevel"
                  value={form.oilLevel}
                  onChange={handleChange}
                  placeholder="Nivel de Aceite"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="initialFuel"
                  value={form.initialFuel}
                  onChange={handleChange}
                  placeholder="Nivel Inicial de Combustible"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="finalFuel"
                  value={form.finalFuel}
                  onChange={handleChange}
                  placeholder="Nivel Final de Combustible"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="initialHourmeter"
                  value={form.initialHourmeter}
                  onChange={handleChange}
                  placeholder="Horómetro Inicial"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="finalHourmeter"
                  value={form.finalHourmeter}
                  onChange={handleChange}
                  placeholder="Horómetro Final"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="coolantLevel"
                  value={form.coolantLevel}
                  onChange={handleChange}
                  placeholder="Nivel Refrigerante"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="hydraulicOilLevel"
                  value={form.hydraulicOilLevel}
                  onChange={handleChange}
                  placeholder="Nivel Aceite Hidráulico"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="brakeFluidLevel"
                  value={form.brakeFluidLevel}
                  onChange={handleChange}
                  placeholder="Nivel Líquido Freno"
                  className="w-full p-1.5 border rounded text-xs"
                />
                <input
                  name="transmissionOilLevel"
                  value={form.transmissionOilLevel}
                  onChange={handleChange}
                  placeholder="Nivel Aceite Transmisión"
                  className="w-full p-1.5 border rounded text-xs"
                />
              </div>

              <div className="mt-4">
                <h3 className="font-medium text-xs mb-2">Imágenes:</h3>
                <ImageUploader images={images} setImages={setImages} />
              </div>

              <select
                name="operatorId"
                value={form.operatorId}
                onChange={handleChange}
                className="w-full p-1.5 border rounded text-xs"
                required
              >
                <option value="">Seleccione Operador</option>
                {operators.map((operator) => (
                  <option key={operator.id} value={operator.id}>
                    {operator.nombres} {operator.apPaterno} {operator.apMaterno}
                  </option>
                ))}
              </select>
              <p>Seleccione Asistentes:</p>
              <select
                name="assistants"
                value={form.assistants}
                onChange={(e) => {
                  const selectedOptions = Array.from(
                    e.target.selectedOptions,
                    (option) => option.value
                  );
                  setForm((prev) => ({ ...prev, assistants: selectedOptions }));
                }}
                multiple
                className="w-full p-1.5 border rounded text-xs"
              >
                {assistants.map((assistant) => (
                  <option key={assistant.id} value={assistant.id}>
                    {assistant.nombres} {assistant.apPaterno}{" "}
                    {assistant.apMaterno}
                  </option>
                ))}
              </select>

              <input
                name="generalState"
                value={form.generalState}
                onChange={handleChange}
                placeholder="Estado General"
                className="w-full p-1.5 border rounded text-xs"
              />

              <div className="flex justify-between">
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-blue-500 text-white px-3 py-1.5 rounded text-xs"
                >
                  {loading ? "Guardando..." : "Guardar"}
                </button>

                {form.id && (
                  <button
                    type="button"
                    onClick={handleDelete}
                    disabled={loading}
                    className="bg-red-500 text-white px-3 py-1.5 rounded text-xs"
                  >
                    {loading ? "Eliminando..." : "Eliminar"}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4 modal-background">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="bg-gray-100 px-6 py-4 border-b border-gray-200 flex justify-between items-center">
              <h2 className="text-xs md:text-xl font-semibold text-gray-800">
                Informe Diario de Maquinaria
              </h2>
              <button
                className="p-1 text-gray-600 hover:text-gray-800 transition-colors duration-200"
                onClick={onClose}
              >
                <FiX size={24} />
              </button>
            </div>
            <div>
              <div className="p-6 px-6 md:px-20 grid grid-cols-1 md:grid-cols-3 gap-x-32 text-sm">
                <div className="col-span-1 md:col-span-2 text-[11px] md:text-xs ">
                  <InfoItem
                    label="Maquinaria"
                    value={getMachineryName(form.machineryId)}
                  />
                </div>
                <div className="col-span-1 text-[11px] md:text-xs ">
                  <InfoItem label="Fecha" value={form.date} />
                </div>
                <div className="col-span-1 md:col-span-2 text-[11px] md:text-xs">
                  <InfoItem
                    label="Estado de Aprobación"
                    value={
                      <span
                        className={`${
                          form.approvalStatus === 0
                            ? "text-yellow-500" // PENDIENTE
                            : form.approvalStatus === 1
                            ? "text-green-500" // APROBADO
                            : form.approvalStatus === 2
                            ? "text-red-500" // DESAPROBADO
                            : "text-gray-500" // Desconocido
                        }`}
                      >
                        {`${
                          form.approvalStatus === 0
                            ? "PENDIENTE"
                            : form.approvalStatus === 1
                            ? "APROBADO"
                            : form.approvalStatus === 2
                            ? "DESAPROBADO"
                            : "Desconocido"
                        }` || "Pendiente"}
                      </span>
                    }
                  />
                </div>
              </div>
              <div className="p-6 gap-6 text-sm">
                <h3 className="font-semibold text-sm mb-2 text-gray-700">
                  Actividades:
                </h3>
                <ul className="bg-gray-50 rounded-xl space-y-2 text-[11px] md:text-xs  mx-0 md:mx-20 border border-slate-500">
                  <li className="grid items-center md:space-x-2 grid-cols-3 text-[12px] md:text-xs bg-slate-500 text-white px-6 py-2 rounded-t-xl pl-5 border">
                    <span>Hora</span>
                    <span>Actividad</span>
                    <span>Disposición</span>
                  </li>
                  {form.activities.map((act, index) => (
                    <li
                      key={index}
                      className="grid items-center space-x-2 grid-cols-3  border-slate-300 border-b-2 last:border-b-0 pl-2 md:pl-5"
                    >
                      <span className="font-medium text-gray-600">
                        {act.startTime}:
                      </span>
                      <span>{act.description}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-gray-200 px-6 py-4 border-t border-gray-200 flex flex-col mb-2  gap-4">
                <h3 className="font-semibold text-sm  text-gray-700">
                  Observaciones:
                </h3>
                <p className="text-xs -mt-4 pl-4">-{form.observations}</p>
                <div className="grid md:grid-cols-2 md:gap-x-10 w-full text-[11px] md:text-xs px-4 md:px-20">
                  <InfoItem
                    label="Turno Mañana"
                    value={`${form.morningShift.start} - ${form.morningShift.end}`}
                  />
                  <InfoItem label="Calentamiento" value={form.warmupTime} />
                  <InfoItem
                    label="Turno Tarde"
                    value={`${form.afternoonShift.start} - ${form.afternoonShift.end}`}
                  />
                  <InfoItem label="Total Kilómetros" value={form.totalKm} />
                  <InfoItem label="Nivel Aceite" value={form.oilLevel} />
                  <InfoItem label="Total Horas" value={form.totalHours} />
                  <InfoItem
                    label="Combustible"
                    value={`${form.initialFuel} - ${form.finalFuel}`}
                  />
                  <InfoItem
                    label="Operario"
                    value={getUserName(form.operatorId)}
                  />
                  <InfoItem
                    label="Asistentes"
                    value={form.assistants
                      .map(getUserName)
                      .join(", ")}
                  />
                  <InfoItem label="Estado General" value={form.generalState} />
                </div>
                <div className="flex justify-end items-center gap-4 w-full">
                  <PDFDownloadLink
                    document={<DailyActivityPDF data={getPDFData(form)} />}
                    fileName={`actividad_diaria_${form.id}.pdf`}
                  >
                    {({ loading }) => (
                      <button
                        className="text-blue-500 hover:text-blue-700 flex items-center text-[11px] md:text-xs"
                        disabled={loading}
                      >
                        <FiPrinter className="h-5 w-5" />
                        Imprimir
                      </button>
                    )}
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DailyActivitiesModal;

const InfoItem = ({ label, value }) => (
  <div className="flex justify-between">
    <span className="font-medium text-gray-700">{label}:</span>
    <span className="text-gray-600">{value}</span>
  </div>
);
