import React, { useState, useEffect } from "react";
import DailyActivitiesOnlineList from "./DailyActivitiesOnlineList";
import DailyActivitiesOnlineModal from "./DailyActivitiesOnlineModal";
import Loader from "../ayuda/Loader";

const DailyActivitiesDashboard = ({
  usersios,
  maquineries,
  dni,
  isSuperAdmin,
}) => {
  // Obtener fecha de hace una semana y hoy
  const today = new Date(Date.now() + 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0];
  const lastWeek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0];

  const [activities, setActivities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [loading, setLoading] = useState(false);

  // Inicializar filtros con valores predeterminados
  const [startDate, setStartDate] = useState(lastWeek);
  const [endDate, setEndDate] = useState(today);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("1"); // Estado APROBADO por defecto

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://velimaqappoffline.smartaccesorios.shop/.phpOffSSOMA/dailyActivities.php"
      );
      const data = await response.json();
      setActivities(data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  const handleEditActivity = (activity) => {
    setCurrentActivity(activity);
    setIsModalOpen(true);
  };

  const handleUpdateActivity = (updatedActivity) => {
    setActivities((prevActivities) =>
      prevActivities.map((activity) =>
        activity.id === updatedActivity.id ? updatedActivity : activity
      )
    );
  };

  const getUserName = (id) => {
    const user = usersios.find((u) => u.id === Number(id));
    return user
      ? `${user.nombres} ${user.apPaterno} ${user.apMaterno}`
      : "Usuario no Registrado";
  };

  // Obtener lista de operadores basada en las actividades filtradas por fecha
  const getFilteredOperators = () => {
    const filteredActivities = activities.filter((activity) => {
      return !startDate || !endDate
        ? true
        : activity.date >= startDate && activity.date <= endDate;
    });

    const uniqueOperatorIds = [
      ...new Set(filteredActivities.map((a) => a.operatorId)),
    ];
    return uniqueOperatorIds;
  };

  // Función para filtrar actividades
  const getFilteredActivities = () => {
    return activities.filter((activity) => {
      const matchesDate =
        !startDate || !endDate
          ? true
          : activity.date >= startDate && activity.date <= endDate;

      const matchesOperator =
        !selectedOperator || activity.operatorId === selectedOperator;

      const matchesStatus =
        selectedStatus === "" ||
        Number(activity.approvalStatus) === Number(selectedStatus);

      return matchesDate && matchesOperator && matchesStatus;
    });
  };

  if (loading) {
    return <Loader text="Cargando..." />;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Panel de Actividades Diarias</h1>

      {/* Filtros */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6 ">
        <div className="col-span-1 shadow-xl shadow-black/10 p-4 rounded-md bg-green-50">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Fecha Inicio
          </label>
          <input
            type="date"
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-span-1 shadow-xl shadow-black/10 p-4 rounded-md bg-green-50">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Fecha Fin
          </label>
          <input
            type="date"
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="col-span-1 shadow-xl shadow-black/10 p-4 rounded-md bg-green-50">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Operador
          </label>
          <select
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            value={selectedOperator}
            onChange={(e) => setSelectedOperator(e.target.value)}
          >
            <option value="">Todos</option>
            {getFilteredOperators().map((operatorId) => (
              <option key={operatorId} value={operatorId}>
                {getUserName(operatorId).toUpperCase()}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-1 shadow-xl shadow-black/10 p-4 rounded-md bg-green-50">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Estado
          </label>
          <select
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="0">PENDIENTE</option>
            <option value="1">APROBADO</option>
            <option value="2">DESAPROBADO</option>
          </select>
        </div>
      </div>

      <DailyActivitiesOnlineList
        activities={getFilteredActivities()}
        usersios={usersios}
        maquineries={maquineries}
        onEditActivity={handleEditActivity}
      />

      {/* Modal */}
      {isModalOpen && (
        <DailyActivitiesOnlineModal
          isSuperAdmin={isSuperAdmin}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          activity={currentActivity}
          usersios={usersios}
          maquineries={maquineries}
          dni={dni}
          onUpdateActivity={handleUpdateActivity} // Pasar la función aquí
        />
      )}
    </div>
  );
};

export default DailyActivitiesDashboard;
