import React, { useEffect, useState } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import logo from "../../assets/logo_velimaq.png"

// Registrar fuentes personalizadas si es necesario
Font.register({
  family: 'Helvetica',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1UpcaXcl0Aw.ttf' }, // Regular
    { src: 'https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OUuhp.ttf', fontWeight: 'bold' }, // Bold
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Helvetica',
    fontSize: 12,
    backgroundColor: '#F9FAFB', // Fondo gris muy claro para todo el documento
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#1F2937', // Gris oscuro para títulos
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#D1D5DB', // Borde gris claro
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#FFFFFF', // Filas blancas
  },
  tableColHeader: {
    width: '12%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#D1D5DB',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#E5E7EB', // Encabezados con fondo gris claro
    color: '#1F2937',
  },
  tableCol: {
    width: '12%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#D1D5DB',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    textAlign: 'center',
  },
  activityCol: {
    width: '40%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#D1D5DB',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    textAlign: 'left',
  },
  header: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#D1D5DB',
    marginBottom: 10,
    backgroundColor: '#FFFFFF',
    height: 80,
  },
  logoContainer: {
    width: '20%',
    borderRightWidth: 1,
    borderRightColor: '#D1D5DB',
    padding: 5,
    backgroundColor: '#F6F7F8',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 60,
    height: 60,
    objectFit: 'contain',
  },
  titleContainer: {
    width: '60%',
    borderRightWidth: 1,
    borderRightColor: '#D1D5DB',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F4F6',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#111827',
    marginBottom: 2,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#374151',
  },
  codeContainer: {
    width: '20%',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F4F6',
  },
  codeText: {
    fontSize: 10,
    color: '#4B5563',
    marginBottom: 2,
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  infoText: {
    width: '32%',
    color: '#4B5563', // Gris medio para texto informativo
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: 'center',
    fontSize: 10,
    color: '#9CA3AF', // Gris claro para el pie de página
  },
  observations: {
    marginTop: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: '#D1D5DB',
    borderRadius: 5,
    backgroundColor: '#ECFDF5', // Verde muy claro para observaciones
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: '#D1D5DB',
  },
  section: {
    width: '50%',
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: '#D1D5DB',
    backgroundColor: '#FFFFFF',
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#D1D5DB',
    minHeight: 20,
    alignItems: 'center',
  },
  label: {
    width: '40%',
    fontWeight: 'bold',
    fontSize: 10,
    color: '#374151', // Gris oscuro para etiquetas
  },
  value: {
    width: '60%',
    fontSize: 10,
    color: '#4B5563', // Gris medio para valores
  },
  fullWidthRow: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#D1D5DB',
    padding: 5,
  },
  observationsTitle: {
    fontWeight: 'bold',
    fontSize: 10,
    marginBottom: 5,
    color: '#065F46', // Verde oscuro para el título de observaciones
  },
  observationsText: {
    fontSize: 10,
    color: '#047857', // Verde medio para el texto de observaciones
  },
  signatureSection: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  signatureBox: {
    width: '45%',
    height: 100,
    border: '1pt solid #D1D5DB',
    padding: 5,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  signatureImage: {
    width: 80,
    height: 80,
    objectFit: 'contain',
  },
  signatureLabel: {
    fontSize: 10,
    textAlign: 'center',
    marginTop: 5,
    color: '#4B5563', // Gris medio para etiquetas de firma
  },
  imagesContainer: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageContainer: {
    width: '100%',
    marginBottom: 20,
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: 600,
    objectFit: 'contain',
  },
  imageLabel: {
    fontSize: 10,
    textAlign: 'center',
    marginTop: 5,
    color: '#4B5563',
  },
  imagePage: {
    padding: 30,
    fontFamily: 'Helvetica',
    fontSize: 12,
    backgroundColor: '#F9FAFB',
  },
  imageHeader: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#D1D5DB',
    marginBottom: 20,
    backgroundColor: '#FFFFFF',
  },
  imageTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    color: '#1F2937',
  },
});

const ActivityRow = ({ activity, index }) => (
  <View style={styles.tableRow}>
    <Text style={styles.activityCol}>{activity.description || ` `}</Text>
    <Text style={styles.tableCol}>{activity.startTime || ''}</Text>
    <Text style={styles.tableCol}>{activity.status === 'produccion' ? 'X' : ''}</Text>
    <Text style={styles.tableCol}>{activity.status === 'disponible' ? 'X' : ''}</Text>
    <Text style={styles.tableCol}>{activity.status === 'mantenimiento' ? 'X' : ''}</Text>
    <Text style={styles.tableCol}>{activity.status === 'varado' ? 'X' : ''}</Text>
  </View>
);

const ActivitiesTable = ({ data }) => {
  // Asegurar que haya al menos 16 filas
  const activities = [...(data.activities || []), ...Array(16).fill({})].slice(0, 17);

  return (
    <>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={[styles.tableColHeader, { width: '40%' }]}> </Text>
          <Text style={[styles.tableColHeader, { width: '60%' }]}>DISPOSICION DEL EQUIPO</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={[styles.tableColHeader, { width: '40%' }]}>ACTIVIDAD</Text>
          <Text style={styles.tableColHeader}>HORA</Text>
          <Text style={styles.tableColHeader}>P</Text>
          <Text style={styles.tableColHeader}>D</Text>
          <Text style={styles.tableColHeader}>M</Text>
          <Text style={styles.tableColHeader}>V</Text>
        </View>
        {activities.map((activity, index) => (
          <ActivityRow key={index} activity={activity} index={index} />
        ))}
      </View>
    </>
  );
};

const InfoRow = ({ label, value }) => (
  <View style={styles.row}>
    <Text style={styles.label}>{label}</Text>
    <Text style={styles.value}>{value}</Text>
  </View>
);

const InfoRow2 = ({ label1, value1, label2, value2 }) => (
  <View style={styles.infoRow2}>
    <Text style={[styles.infoText, { width: '60%' }]}>
      <Text style={styles.fontWeight}>{label1}</Text> {value1}
    </Text>
    {label2 && (
      <Text style={styles.infoText}>
        <Text style={{ fontWeight: 'bold' }}>{label2}</Text> {value2}
      </Text>
    )}    
  </View>
);

const DetailSection = ({ data }) => (
  <View style={styles.container}>
    <View style={styles.fullWidthRow}>
      <Text style={styles.observationsTitle}>OBSERVACIONES:</Text>
      <Text style={styles.observationsText}>{data.observations}</Text>
    </View>
    <View style={styles.section}>
      <InfoRow label="Turno Mañana:" value={`${data.morningShift.start} - ${data.morningShift.end}`} />
      <InfoRow label="Turno Tarde:" value={`${data.afternoonShift.start} - ${data.afternoonShift.end}`} />
      <InfoRow label="Nivel Aceite:" value={data.oilLevel} />
      <InfoRow label="Combustible:" value={`${data.initialFuel} - ${data.finalFuel}`} />
      <InfoRow label="Horómetro:" value={`${data.initialHourmeter} - ${data.finalHourmeter}`} />
      <InfoRow label="Nivel Refrigerante:" value={data.coolantLevel} />
      <InfoRow label="Nivel Aceite Hidráulico:" value={data.hydraulicOilLevel} />
      <InfoRow label="Nivel Líquido Freno:" value={data.brakeFluidLevel} />
      <InfoRow label="Nivel Aceite Transmisión:" value={data.transmissionOilLevel} />
      <InfoRow label="Estado General:" value={data.generalState} />
    </View>
    <View style={styles.section}>
      <InfoRow label="Calentamiento:" value={data.warmupTime} />
      <InfoRow label="Total Kilómetros:" value={data.totalKm} />
      <InfoRow label="Total Horas:" value={data.totalHours} />
      <InfoRow label="Operario:" value={data.operator} />
      <InfoRow label="Ayudantes:" value={data.assistants} />
    </View>    
  </View>
);

const convertBase64ToFile = (base64Data) => {
  const byteString = atob(base64Data.split(',')[1]);
  const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });
  return blob;
};

// Función para convertir WebP a base64
const webpToBase64 = (webpFile) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = document.createElement('img');
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(webpFile);
  });
};

const renderImagePage = (blob, index, total) => (
  <Page key={`image-${index}`} size="A4" style={styles.imagePage}>
    <View style={styles.imageHeader}>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={logo} />
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>ANEXO</Text>
        <Text style={styles.subTitle}>IMÁGENES DEL INFORME DIARIO</Text>
      </View>
      <View style={styles.codeContainer}>
        <Text style={styles.codeText}>Código: BPR-FOR03</Text>
        <Text style={styles.codeText}>Versión: 01</Text>
      </View>
    </View>

    <View style={styles.imageContainer}>
      <Text style={styles.imageTitle}>Imagen {index + 1} de {total}</Text>
      <Image
        style={styles.image}
        src={URL.createObjectURL(blob)}
      />
    </View>

    <Text
      style={styles.footer}
      render={({ pageNumber, totalPages }) =>
        `Página ${pageNumber} de ${totalPages}`
      }
      fixed
    />
  </Page>
);

const DailyActivityPDF = ({ data }) => {
  const [signatureBlob, setSignatureBlob] = useState(null);
  const [imageBlobs, setImageBlobs] = useState([]);

  useEffect(() => {
    const convertSignature = async () => {
      if (data.operatorSignature && data.operatorSignature[0] && data.operatorSignature[0] instanceof File) {
        try {
          const base64Data = await webpToBase64(data.operatorSignature[0]);
          const blob = convertBase64ToFile(base64Data);
          setSignatureBlob(blob);
        } catch (error) {
          console.error("Error converting signature:", error);
        }
      }
    };

    convertSignature();
  }, [data.operatorSignature]);

  useEffect(() => {
    const convertImages = async () => {
      if (data.images && data.images.length > 0) {
        const blobs = await Promise.all(
          data.images.map(async (image) => {
            if (image instanceof File) {
              const base64Data = await webpToBase64(image);
              return convertBase64ToFile(base64Data);
            }
            return null;
          })
        );
        setImageBlobs(blobs.filter(blob => blob !== null));
      }
    };

    convertImages();
  }, [data.images]);

  // Asegurarse de que los datos del operador y asistentes estén disponibles
  const operatorName = data.operator || data.operatorName || "No especificado";
  const assistantsNames = data.assistants || data.assistantsNames || "No especificados";

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Encabezado */}
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Image style={styles.logo} src={logo} />
          </View>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>FORMATO</Text>
            <Text style={styles.subTitle}>INFORME DIARIO DE MAQUINARIA</Text>
          </View>
          <View style={styles.codeContainer}>
            <Text style={styles.codeText}>Código: BPR-FOR03</Text>
            <Text style={styles.codeText}>Versión: 01</Text>
          </View>
        </View>

        {/* Información General */}
        <InfoRow2
          label1="Maquinaria:"
          value1={data.machineryName}
          label2="Fecha:"
          value2={data.date}
        />

        {/* Actividades */}
        <ActivitiesTable data={data} />

        {/* Seccion Detalle */}
        <DetailSection 
          data={{
            ...data,
            operator: operatorName,
            assistants: assistantsNames
          }} 
        />

        {/* Pie de página */}
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `Página ${pageNumber} de ${totalPages}`
          }
          fixed
        />
      </Page>

      {/* Páginas de imágenes */}
      {imageBlobs.map((blob, index) => 
        renderImagePage(blob, index, imageBlobs.length)
      )}

      {/* Página de firmas */}
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Image style={styles.logo} src={logo} />
          </View>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>FORMATO</Text>
            <Text style={styles.subTitle}>INFORME DIARIO DE MAQUINARIA</Text>
          </View>
          <View style={styles.codeContainer}>
            <Text style={styles.codeText}>Código: BPR-FOR03</Text>
            <Text style={styles.codeText}>Versión: 01</Text>
          </View>
        </View>

        <View style={styles.signatureSection}>
          <View style={styles.signatureBox}>
            <Text style={styles.signatureLabel}>Firma del Operador</Text>
            {signatureBlob && (
              <Image
                style={styles.signatureImage}
                src={URL.createObjectURL(signatureBlob)}
              />
            )}
          </View>
          <View style={styles.signatureBox}>
            <Text style={styles.signatureLabel}>Firma Asistentes de Ingeniería</Text>
          </View>
        </View>

        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `Página ${pageNumber} de ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default DailyActivityPDF;