// src/components/SyncedReportsList.js
import React, { useState, useEffect } from "react";
import { FiPrinter, FiTrash, FiEye } from "react-icons/fi";
import ModalSyncedReports from "./ayuda/ModalSyncedReports";
import Loader from './ayuda/Loader'
import generatePDF from "./ayuda/MaintenanceReportPDF"
import generateFallaPDF from "./ayuda/FallasReportPDF"
import handleGeneratevPDF from "./ayuda/ReporteRecepcionesPDF";

const SyncedReportsList = ( { usersios } ) => {
  const [syncedReports, setSyncedReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [formIdFilter, setFormIdFilter] = useState("");
  const [senderNameFilter, setSenderNameFilter] = useState("");
  const [idNumberFilter, setIdNumberFilter] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    endDate: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  
  useEffect(() => {
    fetchSyncedReports();
  }, []);

  useEffect(() => {
    filterReports();
  }, [syncedReports, formIdFilter, senderNameFilter, dateRange, idNumberFilter]);

  const fetchSyncedReports = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        "https://velimaqappoffline.smartaccesorios.shop/.phpOffSSOMA/get_synced_reports.php"
      );
      const data = await response.json();
      setSyncedReports(data);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching synced reports:", error);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text || "";
  };

  const handleGeneratePDF = (reporte) => {
    console.log(reporte)
    if (reporte.form_id === "FO-INFORME-FALLAS"){
      generateFallaPDF(reporte)
    } else if (reporte.form_id === "ENTREGA/RECEPCION DE UNIDADES") {
      handleGeneratevPDF(reporte)
    } else {
      generatePDF(reporte);
    }
  }

  const handleDeleteReport = (report) => {
    // Implementar la eliminación del reporte
  };

  const handleViewReport = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const getUserNameByDni = (dni) => {
    // Convertir el DNI a string para asegurar una comparación consistente
    const dniStr = dni.toString();
    console.log('Buscando usuario con DNI:', dniStr);
    console.log('Usersios disponibles:', usersios);
    
    const user = usersios.find((user) => user.dni.toString() === dniStr);
    console.log('Usuario encontrado:', user);
    
    if (user) {
      return `${user.nombres} ${user.apPaterno} ${user.apMaterno}`;
    } else {
      console.warn(`No se encontró usuario con DNI: ${dniStr}`);
      return `${dniStr} - Usuario no Registrado`;
    }
  };

  const getDateFromUniqueId = (uniqueId) => {
    const dateStr = uniqueId.split('_')[1];
    const [day, month, yearTime] = dateStr.split('/');
    const [year, time] = yearTime.split(' ');
    return new Date(`20${year}-${month}-${day} ${time}`);
  };

  const filterReports = () => {
    // Solo mostrar loader para filtros complejos (fechas o usuarios)
    // const isComplexFilter = dateRange.startDate || dateRange.endDate || senderNameFilter;
    
    // if (isComplexFilter) {
    //   setIsFiltering(true);
    // }
    
    // setTimeout(() => {
      let filtered = [...syncedReports];

      if (formIdFilter) {
        filtered = filtered.filter(report => 
          report.form_id.toLowerCase().includes(formIdFilter.toLowerCase())
        );
      }

      if (senderNameFilter) {
        filtered = filtered.filter(report => 
          report.sender_name === senderNameFilter
        );
      }

      if (idNumberFilter) {
        filtered = filtered.filter(report => 
          report.id.toString().includes(idNumberFilter)
        );
      }

      const startDate = new Date(dateRange.startDate);
      const endDate = new Date(dateRange.endDate);
      endDate.setHours(23, 59, 59);

      filtered = filtered.filter(report => {
        const reportDate = getDateFromUniqueId(report.unique_id);
        return reportDate >= startDate && reportDate <= endDate;
      });

      setFilteredReports(filtered);
      // setIsFiltering(false);
    // }, isComplexFilter ? 300 : 0); // Solo delay si es un filtro complejo
  };

  const getActiveUsers = () => {
    // Primero filtrar los reportes por el rango de fechas actual
    const startDate = new Date(dateRange.startDate);
    const endDate = new Date(dateRange.endDate);
    endDate.setHours(23, 59, 59);

    const reportsInDateRange = syncedReports.filter(report => {
      const reportDate = getDateFromUniqueId(report.unique_id);
      return reportDate >= startDate && reportDate <= endDate;
    });

    // Luego obtener los DNIs únicos de esos reportes
    const uniqueSenderDnis = [...new Set(reportsInDateRange.map(report => report.sender_name))];
    
    // Finalmente filtrar los usuarios que tienen reportes en ese rango
    return usersios.filter(user => uniqueSenderDnis.includes(user.dni));
  };

  if (loading) {
    return <Loader text="Cargando..." />;
  }

  const renderReportCard = (report) => {
    return (
      <div
        key={report.id}
        className="p-4 bg-white rounded-lg shadow-xl drop-shadow-xl flex items-center mb-4"
      >
        <div className="flex-shrink-0 mr-4">
          {report.image_paths && report.image_paths.length > 0 ? (
            <div>
              <img
                className="w-16 h-16 rounded-full object-cover"
                src={`https://velimaqappoffline.smartaccesorios.shop/.phpOffSSOMA${report.image_paths[0].substring(1)}`}
                alt="Imagen principal"
              />
              <div className="flex space-x-1 mt-2">
                {report.image_paths.slice(1, 4).map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    className="w-5 h-5 rounded-md shadow-lg shadow-black/20 object-cover"
                    src={`https://velimaqappoffline.smartaccesorios.shop/.phpOffSSOMA${image.substring(1)}`}
                    alt={`Imagen secundaria ${imgIndex + 1}`}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center">
              <span className="text-gray-500 text-xs text-center">
                Sin imágenes
              </span>
            </div>
          )}
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div>
            <h3 className="text-sm font-semibold">
              ( {report.id} ) - {truncateText(report.form_id, 80)} 
            </h3>
            <p className="text-xs text-gray-400">{report.creation_date.slice(2)}</p>
            <p className="text-xs text-gray-600">
              Enviado por: {getUserNameByDni(report.sender_name)}
            </p>
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="flex space-x-2">
              <button
                onClick={() => handleGeneratePDF(report)}
                className="text-blue-500 hover:text-blue-700"
              >
                <FiPrinter className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleDeleteReport(report)}
                className="text-red-500 hover:text-red-700"
              >
                <FiTrash className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleViewReport(report)}
                className="text-green-500 hover:text-green-700"
              >
                <FiEye className="h-5 w-5" />
              </button>
            </div>
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">Formularios Sincronizados</h2>
      
      {/* Filtros */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
        <div className="flex flex-col">
          <label className="text-sm font-medium mb-1">Número de ID:</label>
          <input
            type="text"
            value={idNumberFilter}
            onChange={(e) => setIdNumberFilter(e.target.value)}
            className="border rounded-md shadow-lg h-10 shadow-black/20 p-2"
            placeholder="Filtrar por número..."
          />
        </div>

        <div className="flex flex-col">
          <label className="text-sm font-medium mb-1">Tipo de Formulario:</label>
          <input
            type="text"
            value={formIdFilter}
            onChange={(e) => setFormIdFilter(e.target.value)}
            className="border rounded-md shadow-lg h-10 shadow-black/20 p-2"
            placeholder="Filtrar por tipo..."
          />
        </div>
        
        <div className="flex flex-col">
          <label className="text-sm font-medium mb-1">Usuario Remitente:</label>
          <select
            value={senderNameFilter}
            onChange={(e) => setSenderNameFilter(e.target.value)}
            className="border rounded-md shadow-lg h-10 shadow-black/20 p-2"
          >
            <option value="">Todos los usuarios</option>
            {getActiveUsers()
              .sort((a, b) => `${a.nombres} ${a.apPaterno}`.localeCompare(`${b.nombres} ${b.apPaterno}`))
              .map(user => (
                <option key={user.dni} value={user.dni}>
                  {`${user.nombres} ${user.apPaterno} - ${user.dni}`}
                </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label className="text-sm font-medium mb-1">Rango de Fechas:</label>
          <div className="flex gap-2">
            <input
              type="date"
              value={dateRange.startDate}
              onChange={(e) => setDateRange({...dateRange, startDate: e.target.value})}
              className="border rounded-md shadow-lg h-10 shadow-black/20 p-2 flex-1"
            />
            <input
              type="date"
              value={dateRange.endDate}
              onChange={(e) => setDateRange({...dateRange, endDate: e.target.value})}
              className="border rounded-md shadow-lg h-10 shadow-black/20 p-2 flex-1"
            />
          </div>
        </div>
      </div>

      {loading ? (
        <Loader text="Cargando datos..." />
      ) : (
        // isFiltering ? (
        //   <Loader text="Aplicando filtros..." />
        // ) : (
          // Ordenar los reportes por id de mayor a menor antes de mapearlos
          [...filteredReports]
            .sort((a, b) => b.id - a.id)
            .map(renderReportCard)
        // )
      )}
      
      <ModalSyncedReports
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        report={selectedReport}
      />
    </div>
  );
};

export default SyncedReportsList;