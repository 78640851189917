import React, { useEffect, useState } from "react";
import { FiPrinter, FiX } from "react-icons/fi";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DailyActivityPDF from "./DailyActivityPDF";
import axios from "axios";
import AlertModal from "../ayuda/AlertModals";

const DailyActivitiesModal = ({
  isOpen,
  onClose,
  activity,
  usersios,
  maquineries,
  dni,
  onUpdateActivity,
  isSuperAdmin,
  isSupervisor
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approvalObservation, setApprovalObservation] = useState(activity.approvalObservation);
  // Deshabilitar el scroll en el body cuando el modal está abierto
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";

    // Limpiar el estilo al desmontar
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      e.stopPropagation(); // Detener propagación
      onClose(); // Llama a onClose cuando se presiona Escape
    }
  };

  const handleClickOutside = (e) => {
    // Verifica si el clic fue fuera del modal
    if (e.target.classList.contains("modal-background")) {
      onClose();
    }
  };

  useEffect(() => {
    // Agregar el evento de escucha para la tecla Escape
    document.addEventListener("keydown", handleKeyPress);
    // Agregar el evento de escucha para clics fuera del modal
    document.addEventListener("click", handleClickOutside);

    // Limpiar los eventos de escucha al desmontar
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getMachineryName = (id) => {
    const machinery = maquineries.find((m) => m.id === Number(id));
    return machinery ? machinery.nombre : "Desconocido";
  };

  const getUserName = (id) => {
    const user = usersios.find((u) => u.id === Number(id));
    return user
      ? `${user.nombres} ${user.apPaterno} ${user.apMaterno}`
      : "Desconocido";
  };

  const newActivity = (activity) => {
    return {
      ...activity,
      machineryName: getMachineryName(activity.machineryId),
      operator: getUserName(activity.operatorId),
      assistants: activity.assistants.split(",").map(getUserName).join(", "),
      initialHourmeter: activity.initialHourmeter,
      finalHourmeter: activity.finalHourmeter,
      coolantLevel: activity.coolantLevel,
      hydraulicOilLevel: activity.hydraulicOilLevel,
      brakeFluidLevel: activity.brakeFluidLevel,
      transmissionOilLevel: activity.transmissionOilLevel
    };
  };

  const [approvateStatus, setApprovateStatus] = useState();

  const handleApproval = async (newStatus) => {
    try {
      const response = await axios.post(
        "https://velimaqappoffline.smartaccesorios.shop/.phpOffSSOMA/approvalStatus.php",
        {
          dni: dni,
          approvalStatus: newStatus,
          id: activity.id,
          approvalObservation: approvalObservation
        }
      );
      if (response.data.status === "success") {
        setApprovateStatus(newStatus);
        setIsModalOpen(true);
        const updatedActivity = {
          ...activity,
          approvalStatus: Number(newStatus),
          approvalObservation: approvalObservation
        };
        onUpdateActivity(updatedActivity);
        setApprovalObservation(''); // Limpiar el campo después de enviar
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!isOpen || !activity) return null;

  const getUserNameDNI = (dni) => {
    const user = usersios.find((u) => Number(u.dni) === Number(dni));
    return user
      ? `${user.nombres} ${user.apPaterno} ${user.apMaterno}`
      : "Usuario no Registrado";
  };
  const getUserNameDNINoName = (dni) => {
    const user = usersios.find((u) => Number(u.dni) === Number(dni));
    return user
      ? `por ${user.nombres} ${user.apPaterno} ${user.apMaterno}`
      : "por mi persona";
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4 modal-background">
      <AlertModal
        mensaje={`Parte Diario ${
          approvateStatus === "0"
            ? "PENDIENTE"
            : approvateStatus === "1"
            ? "APROBADO"
            : approvateStatus === "2"
            ? "DESAPROBADO"
            : "Desconocido"
        } correctamente `}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        cerrarModal={onClose}
      />
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        {/* Header mejorado */}
        <div className="bg-slate-700 px-8 py-6 border-b border-gray-200 flex justify-between items-center rounded-t-lg">
          <div className="flex flex-col">
            <h2 className="text-lg md:text-2xl font-bold text-white">
              Informe Diario de Maquinarias
            </h2>
            <p className="text-slate-300 text-sm mt-1">
              ID: {activity.id} - Fecha: {activity.date}
            </p>
          </div>
          <button
            className="p-2 text-white hover:bg-slate-600 rounded-full transition-colors duration-200"
            onClick={onClose}
          >
            <FiX size={24} />
          </button>
        </div>

        <div className="space-y-6 py-6"> {/* Contenedor principal con espaciado uniforme */}
          <div className="px-8 grid grid-cols-1 md:grid-cols-3 gap-6 text-sm">
            <div className="col-span-1 md:col-span-2 text-[11px] md:text-xs">
              <InfoItem
                label="Maquinaria"
                value={getMachineryName(activity.machineryId)}
              />
            </div>
            <div className="col-span-1 text-[11px] md:text-xs">
              <InfoItem label="Fecha" value={activity.date} />
            </div>
            <div className="col-span-1 md:col-span-2 text-[11px] md:text-xs">
              <InfoItem
                label="Estado de Aprobación"
                value={
                  <span
                    className={`font-medium ${
                      activity.approvalStatus === 0
                        ? "text-yellow-500"
                        : activity.approvalStatus === 1
                        ? "text-green-500"
                        : activity.approvalStatus === 2
                        ? "text-red-500"
                        : "text-gray-500"
                    }`}
                  >
                    {activity.approvalStatus === 0
                      ? "PENDIENTE"
                      : activity.approvalStatus === 1
                      ? `APROBADO ${getUserNameDNINoName(activity.approvalUser)} ${activity.approvDate === "0000-00-00 00:00:00" ? "" : "- " + activity.approvDate}`
                      : activity.approvalStatus === 2
                      ? `DESAPROBADO ${getUserNameDNINoName(activity.approvalUser)} - ${activity.approvDate === "0000-00-00 00:00:00" ? "" : "- " + activity.approvDate}`
                      : "Desconocido"}
                  </span>
                }
              />              
            </div>
          </div>

          {/* Sección de Actividades con mejor espaciado */}
          <div className="px-8">
            <h3 className="font-semibold text-base mb-4 text-gray-700">
              Actividades:
            </h3>
            <div className="bg-gray-50 rounded-xl border border-slate-200 overflow-hidden">
              <ul className="space-y-2 text-[11px] md:text-xs">
                <li className="grid items-center md:space-x-2 grid-cols-3 text-[12px] md:text-xs bg-slate-500 text-white px-6 py-2 rounded-t-xl pl-5 border">
                  <span>Hora</span>
                  <span>Actividad</span>
                  <span>Disposición</span>
                </li>
                {activity.activities.map((act, index) => (
                  <li
                    key={index}
                    className="grid items-center space-x-2 grid-cols-3  border-slate-300 border-b-2 last:border-b-0 pl-2 md:pl-5"
                  >
                    <span className="font-medium text-gray-600">
                      {act.startTime}:
                    </span>
                    <span>{act.description}</span>
                    <span
                      className={`rounded-full ${getStatusColor(act.status)}`}
                    >
                      {act.status}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Sección de footer con mejor espaciado */}
          <div className="bg-gray-50 px-8 py-6 border-t">
            <h3 className="font-semibold text-sm  text-gray-700">
              Observaciones:
            </h3>
            <p className="text-xs -mt-4 pl-4">-{activity.observations}</p>
            <div className="grid md:grid-cols-2 md:gap-x-10 w-full text-[11px] md:text-xs px-4 md:px-20">
              <InfoItem
                label="Turno Mañana"
                value={`${activity.morningShift.start} - ${activity.morningShift.end}`}
              />
              <InfoItem label="Calentamiento" value={activity.warmupTime} />
              <InfoItem
                label="Turno Tarde"
                value={`${activity.afternoonShift.start} - ${activity.afternoonShift.end}`}
              />
              <InfoItem label="Total Kilómetros" value={activity.totalKm} />
              <InfoItem label="Nivel Aceite" value={activity.oilLevel} />
              <InfoItem label="Total Horas" value={activity.totalHours} />
              <InfoItem
                label="Combustible"
                value={`${activity.initialFuel} - ${activity.finalFuel}`}
              />
              <InfoItem
                label="Horómetro"
                value={`${activity.initialHourmeter} - ${activity.finalHourmeter}`}
              />
              <InfoItem
                label="Nivel Refrigerante"
                value={activity.coolantLevel}
              />
              <InfoItem
                label="Nivel Aceite Hidráulico"
                value={activity.hydraulicOilLevel}
              />
              <InfoItem
                label="Nivel Líquido Freno"
                value={activity.brakeFluidLevel}
              />
              <InfoItem
                label="Nivel Aceite Transmisión"
                value={activity.transmissionOilLevel}
              />
              <InfoItem
                label="Operario"
                value={getUserName(activity.operatorId)}
              />
              <InfoItem
                label="Asistentes"
                value={activity.assistants
                  .split(",")
                  .map(getUserName)
                  .join(", ")}
              />
              <InfoItem label="Estado General" value={activity.generalState} />
            </div>
            <div className="flex flex-col md:flex-row justify-end items-center gap-4 w-full">
              {(isSuperAdmin || isSupervisor) && (
                <input
                  type="text"
                  placeholder="Ingrese observación"
                  value={approvalObservation}
                  onChange={(e) => setApprovalObservation(e.target.value)}
                  className="w-full md:w-64 px-3 py-2 text-xs border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              )}
              <PDFDownloadLink
                document={<DailyActivityPDF data={newActivity(activity)} />}
                fileName={`actividad_diaria_${activity.id}.pdf`}
              >
                {({ loading }) => (
                  <button
                    className="text-blue-500 hover:text-blue-700 flex items-center text-[11px] md:text-xs"
                    disabled={loading}
                  >
                    <FiPrinter className="h-5 w-5 " />
                    Imprimir
                  </button>
                )}
              </PDFDownloadLink>
              {(isSuperAdmin || isSupervisor) && <>
                {activity.approvalStatus === 0 && (
                <button
                  className="bg-green-500 hover:bg-green-600 text-[11px] md:text-xs text-white px-4 py-2 rounded-lg transition-colors duration-200 text-sm font-medium"
                  onClick={() => handleApproval("1")}
                >
                  Aprobar
                </button>
              )}
              {activity.approvalStatus === 0 && (
                <button
                  className="bg-red-500 hover:bg-red-600 text-[11px] md:text-xs text-white px-4 py-2 rounded-lg transition-colors duration-200 text-sm font-medium"
                  onClick={() => handleApproval("2")}
                >
                  Desaprobar
                </button>
              )}
              {activity.approvalStatus === 1 && (
                <button
                  className="bg-red-500 hover:bg-red-600 text-[11px] md:text-xs text-white px-4 py-2 rounded-lg transition-colors duration-200 text-sm font-medium"
                  onClick={() => handleApproval("2")}
                >
                  Desaprobar
                </button>
              )}
              {activity.approvalStatus === 1 && (
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-[11px] md:text-xs text-white px-4 py-2 rounded-lg transition-colors duration-200 text-sm font-medium"
                  onClick={() => handleApproval("0")}
                >
                  Pendiente
                </button>
              )}
              {activity.approvalStatus === 2 && (
                <button
                  className="bg-green-500 hover:bg-green-600 text-[11px] md:text-xs text-white px-4 py-2 rounded-lg transition-colors duration-200 text-sm font-medium"
                  onClick={() => handleApproval("1")}
                >
                  Aprobar
                </button>
              )}
              {activity.approvalStatus === 2 && (
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-[11px] md:text-xs text-white px-4 py-2 rounded-lg transition-colors duration-200 text-sm font-medium"
                  onClick={() => handleApproval("0")}
                >
                  Pendiente
                </button>
              )}</>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoItem = ({ label, value }) => (
  <div className="flex justify-between">
    <span className="font-medium text-gray-700">{label}:</span>
    <span className="text-gray-600">{value}</span>
  </div>
);

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "0":
      return "bg-green-100 text-green-800";
    case "1":
      return "bg-blue-100 text-blue-800";
    case "2":
      return "bg-yellow-100 text-yellow-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

export default DailyActivitiesModal;
